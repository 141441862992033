import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Unsubscribable, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BasicDetailsService } from './basic-details/basic-details.service';
import { ContactsService } from './contacts/contacts.service';
import { OperatorsService } from './contacts/operators.service';
import { WaterSystemService } from './water-system.service';
import { WaterSystemComplianceOfficerService } from './water-system-compliance-officer.service';
import { WaterSystemContactsService } from './water-system-contacts.service';
import { WaterSystemDistrictContactService } from './water-system-district-contact.service';
import { WaterSystemOperatorsService } from './water-system-operators.service';
import { Checkout } from '../domain/checkout';
import { Contact } from '../domain/contact';
import { WaterSystem } from '../domain/water-system';
import { DistrictContactService } from './contacts/district-contact.service';
import { ComplianceOfficerService } from './contacts/compliance-officer.service';
import { NotesService } from './shared/notes/notes.service';
import { WaterSystemNotesService } from './water-system-notes.service';
import { KeycloakProfile } from 'keycloak-js';
import { WaterSystemInspectionService } from './water-system-inspection.service';
import { SiteVisit } from '../domain/site-visit';
import { SiteVisitService } from '../site-visit.service';
import { PageRequest } from '../domain/page-request';
import { SiteVisitCriteria } from '../domain/SiteVisitCriteria';
import { CheckoutService } from './checkout.service';
import { ConfirmatoryActionService } from '../confirmatory-action/confirmatory-action.service';
import { WaterSystemSiteVisitsService } from './water-system-site-visits.service';
import { WaterSystemWellsService } from './water-system-wells.service';
import { WellsService } from './wells/wells.service';
import { WaterSystemStorageFacilitiesService } from './water-system-storage-facility.service';
import { WaterSystemTreatmentFacilitiesService } from './water-system-treatment-facility.service';
import { TreatmentService } from './treatment/treatment.service';
import { StorageFacilityService } from './storage/storage-facility.service';
import { WaterSystemFacilitiesService } from './water-system-facilities.service';
import { WaterSystemSurfaceWaterFacilityService } from './water-system-surface-water-facility.service';
import { SurfaceWaterFacilityService } from './surface-water/surface-water-facility.service';
import { EventService } from '../event.service';
import { Event } from '../domain/event';
import { UnlockRequestEvent } from '../domain/unlock-request-event';
import { EventRequestor } from '../domain/event-requestor';
import { environment } from 'src/environments/environment';
import { ToastService } from '../toast/toast.service';
import { MessageService } from '../message/message.service';
import { Severity } from '../message/Severity';
import { EventType } from '../shared/event-type';
import { DistributionFacilityService } from './distribution/distribution-facility.service';
import { WaterSystemDistributionFacilityService } from './water-system-distribution-facility.service';
import { WaterSystemBoosterStationFacilityService } from './water-system-booster-station-facility.service';
import { WaterSystemInterconnectFacilityService } from './water-system-interconnect-facility.service';
import { InterconnectFacilityService } from './booster-station/interconnect-facility.service';
import { BoosterStationFacilityService } from './booster-station/booster-station-facility.service';
import { SiteVisitsService } from './site-visits/site-visits.service';
import { Visit } from '../domain/visit';
import { DeficienciesService } from './deficiencies/deficiencies.service';
import { WaterSystemDeficienciesService } from './water-system-deficiencies.service';
import {IamService} from '../utils/iam.service';

@Component({
  selector: 'app-water-system',
  templateUrl: './water-system.component.html',
  styleUrls: ['./water-system.component.scss'],
})
export class WaterSystemComponent implements OnInit, OnDestroy {
  private readonly WATER_SYSTEM_URI = 'water-system';

  siteVisit: SiteVisit;

  visits: Visit[];

  waterSystem: Checkout<WaterSystem>;
  contacts: Checkout<Contact[]>;

  private checkout$: Unsubscribable;
  private details$: Unsubscribable;

  isSummaryLoading = true;
  isLoading = {
    waterSystem: false,
    contacts: false,
    operators: false,
    districtContact: false,
    complianceOfficer: false,
    notes: false,
    flow: false,
    visits: false,
    wells: false,
    distribution: false,
    treatment: false,
    storage: false,
    surface: false,
    booster: false,
    interconnect: false,
    deficiencies: false
  };
  isLoadingLastVisit = true;
  isCheckoutWidgetLoading = false;
  isCancelling = false;
  isCheckingIn = false;
  isCollapsed = true;

  error = null;
  invalidPwsId = false;
  skipWaterSystemNavigationLink: string;

  userDetails: KeycloakProfile;
  userRoles: string[];

  redirectTimeout;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private basicDetailsService: BasicDetailsService,
    private complianceOfficerService: ComplianceOfficerService,
    private contactsService: ContactsService,
    private districtContactService: DistrictContactService,
    private notesService: NotesService,
    private operatorsService: OperatorsService,
    private siteVisitService: SiteVisitService,
    private waterSystemService: WaterSystemService,
    private waterSystemContactsService: WaterSystemContactsService,
    private waterSystemComplianceOfficerService: WaterSystemComplianceOfficerService,
    private waterSystemDistrictContactService: WaterSystemDistrictContactService,
    private waterSystemNotesService: WaterSystemNotesService,
    private waterSystemOperatorsService: WaterSystemOperatorsService,
    private waterSystemInspectionService: WaterSystemInspectionService,
    private checkoutService: CheckoutService,
    private confirmatoryActionService: ConfirmatoryActionService,
    private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
    private waterSystemWellsService: WaterSystemWellsService,
    private waterSystemDistributionService: WaterSystemDistributionFacilityService,
    private wellsService: WellsService,
    private distributionService: DistributionFacilityService,
    private waterSystemStorageFacilitiesService: WaterSystemStorageFacilitiesService,
    private storageService: StorageFacilityService,
    private waterSystemTreatmentFacilityService: WaterSystemTreatmentFacilitiesService,
    private treatmentService: TreatmentService,
    private waterSystemSurfaceWaterFacilityService: WaterSystemSurfaceWaterFacilityService,
    private surfaceWaterFacilityService: SurfaceWaterFacilityService,
    private waterSystemFacilitiesService: WaterSystemFacilitiesService,
    private eventService: EventService,
    private toastService: ToastService,
    private messageService: MessageService,
    private waterSystemBoosterStationFacilityService: WaterSystemBoosterStationFacilityService,
    private waterSystemInterconnectFacilityService: WaterSystemInterconnectFacilityService,
    private interconnectFacilityService: InterconnectFacilityService,
    private boosterStationFacilityService: BoosterStationFacilityService,
    private siteVisitsService: SiteVisitsService,
    private deficienciesService: DeficienciesService,
    private waterSystemDeficienciesService: WaterSystemDeficienciesService,
    private iamService: IamService
  ) {}

  async ngOnInit() {
    await this.loadUserDetails();
    this.siteVisit = history.state.data;
    if (this.siteVisit) {
      this.isSummaryLoading = false;
      this.initPage();
    } else {
      this.route.paramMap.subscribe((params) => {
        const id = params.get('id');
        this.loadSummary(id);
      });
    }
    this.skipWaterSystemNavigationLink = `${this.router.url}#water-system-contents`;
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe( (event: NavigationEnd) => {
      this.skipWaterSystemNavigationLink = event.urlAfterRedirects.split('#')[0] + '#water-system-contents';
    });
  }

  initPage() {
    if (this.isOwner && (this.siteVisit.status === 'CHECKED_OUT'
      || this.siteVisit.status === 'CHECKING_OUT'
      || this.siteVisit.status === 'ERROR')) {
      if (this.siteVisit.status === 'CHECKED_OUT' || this.siteVisit.status === 'CHECKING_OUT') {
        this.loadDetails(this.siteVisit.id);
      } else if (this.siteVisit.status === 'ERROR') {
        this.setError(this.siteVisit.statusMessage);
      }
    } else {
      if (this.siteVisit.status === 'CHECKING_IN') {
        this.isCheckingIn = true;
        this.redirectTimeout = setTimeout(() => this.router.navigateByUrl('/home'), 4000);
      } else if (this.siteVisit.status === 'CANCELING') {
        this.isCancelling = true;
        this.redirectTimeout = setTimeout(() => this.router.navigateByUrl('/home'), 4000);
      }
      this.checkoutService.removeWaterSystemLocally(this.siteVisit.id, this.userDetails.username);
      this.loadVisitSummary(this.siteVisit.id);
    }
  }

  async loadUserDetails() {
    this.userDetails = await this.iamService.getUserDetails();
    this.userRoles = this.iamService.getUserRoles();
  }

  loadSummary(id: string) {
    if (id == null) {
      this.invalidPwsId = true;
      return;
    }

    const criteria = new SiteVisitCriteria();
    criteria.pwsId = id;
    const pageRequest = new PageRequest(0, 1);
    this.siteVisitService.searchWithLocalStorage(`${SiteVisitService.checkedOutKey}-${this.userDetails.username}`,
      criteria, pageRequest).subscribe(
      (page) => {
        if (page === null || page.results.length === 0) {
          this.invalidPwsId = true;
        } else {
          this.siteVisit = page.results[0];
          this.initPage();
        }
        this.isSummaryLoading = false;
        this.isCancelling = false;
        this.isCheckingIn = false;
      },
      (error) => {
        console.log(error);
        this.addError(error);
      }
    );
  }

  get percentLoaded(): number {
    return (this.loadedCount / this.loadingTotal) * 100;
  }

  get loadedCount(): number {
    return  Object.values(this.isLoading).filter(l => l === false).length;
  }

  get loadingTotal(): number {
    return Object.values(this.isLoading).length;
  }

  get anyIsLoading(): boolean {
    return !Object.values(this.isLoading).every((l) => l === false);
  }

  setIsLoading(loading: boolean) {
    Object.keys(this.isLoading).forEach((l) => (this.isLoading[l] = loading));
  }

  loadVisitSummary(id: number) {
    this.isLoadingLastVisit = true;
    this.waterSystemSiteVisitsService
    .findAll(id, true)
    .subscribe((sv) => {
      this.siteVisitsService.changeWaterSystemSiteVisits(sv);
      this.siteVisitsService.initialize();
      this.isLoadingLastVisit = false;
    });
  }

  loadDetails(id: number) {
    if (this.router.url === `/water-system/${id}`) {
      this.router.navigate(['contacts'], { relativeTo: this.route });
    }
    this.setIsLoading(true);
    this.waterSystemService.find(this.siteVisit.id).subscribe((ws) => {
      this.basicDetailsService.changeWaterSystem(ws);
      this.waterSystem = ws;
      this.isLoading.waterSystem = false;
    }, (err) => {
      this.addError(err);
    });
    this.waterSystemContactsService.find(this.siteVisit.id).subscribe((c) => {
      this.contactsService.changeWaterSystemContacts(c);
      this.contacts = c;
      this.isLoading.contacts = false;
    }, (err) => {
      this.addError(err);
    });
    this.waterSystemOperatorsService.find(this.siteVisit.id).subscribe((o) => {
      this.operatorsService.changeWaterSystemOperators(o);
      this.isLoading.operators = false;
    }, (err) => {
      this.addError(err);
    });
    this.waterSystemDistrictContactService
      .find(this.siteVisit.id)
      .subscribe((dc) => {
        this.districtContactService.setWaterSystemDistrictContact(dc);
        this.isLoading.districtContact = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemComplianceOfficerService
      .find(this.siteVisit.id)
      .subscribe((co) => {
        this.complianceOfficerService.setWaterSystemComplianceOfficer(co);
        this.isLoading.complianceOfficer = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemNotesService
      .find(this.siteVisit.id, false)
      .subscribe((n) => {
        this.notesService.changeWaterSystemNotes(n);
        this.isLoading.notes = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemFacilitiesService
      .findFlowAll(this.siteVisit.id)
      .subscribe(() => {
        this.waterSystemFacilitiesService.storeFlowGraph(id);
        this.isLoading.flow = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemSiteVisitsService
      .findAll(this.siteVisit.id)
      .subscribe((sv) => {
        this.siteVisitsService.changeWaterSystemSiteVisits(sv);
        this.siteVisitsService.initialize();
        this.isLoading.visits = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemWellsService.find(this.siteVisit.id).subscribe(
      (w) => this.wellsService.changeWaterSystemWells(w),
      (err) => this.addError(err),
      () => this.isLoading.wells = false
    );
    this.waterSystemDistributionService
      .find(this.siteVisit.id)
      .subscribe((d) => {
        this.distributionService.changeWaterSystemFacilities(d);
        this.isLoading.distribution = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemTreatmentFacilityService
      .find(this.siteVisit.id)
      .subscribe((t) => {
        this.treatmentService.changeWaterSystemTreatmentFacilities(t);
        this.isLoading.treatment = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemStorageFacilitiesService
      .find(this.siteVisit.id)
      .subscribe((s) => {
        this.storageService.changeWaterSystemStorageFacilities(s);
        this.isLoading.storage = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemSurfaceWaterFacilityService
      .find(this.siteVisit.id)
      .subscribe((sw) => {
        this.surfaceWaterFacilityService.changeWaterSystemSurfaceWaterFacilities(
          sw
        );
        this.isLoading.surface = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemBoosterStationFacilityService
      .find(this.siteVisit.id)
      .subscribe((bs) => {
        this.boosterStationFacilityService.changeWaterSystemBoosterStationFacilities(
          bs
        );
        this.isLoading.booster = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemInterconnectFacilityService
      .find(this.siteVisit.id)
      .subscribe((ic) => {
        this.interconnectFacilityService.changeWaterSystemInterconnectFacilities(
          ic
        );
        this.isLoading.interconnect = false;
      }, (err) => {
        this.addError(err);
      });
    this.waterSystemDeficienciesService
      .find(this.siteVisit.id)
      .subscribe((de) => {
        this.deficienciesService.changeWaterSystemDeficiencies(
          de
        );
        this.isLoading.deficiencies = false;
      }, (err) => {
        this.addError(err);
      });
  }

  addError(error) {
    if (error && error.error && error.error.statusMessage) {
      this.setError(error.error.statusMessage);
    } else {
      this.setError(error.message);
    }
  }

  setError(errorMessage: string) {
    this.error = errorMessage;
    this.setIsLoading(false);
  }

  ngOnDestroy(): void {
    if (this.checkout$ != null) {
      this.checkout$.unsubscribe();
    }
    if (this.details$ != null) {
      this.details$.unsubscribe();
    }
    clearTimeout(this.redirectTimeout);
  }

  get isOwner(): boolean {
    return (
      this.siteVisit &&
      this.userDetails &&
      this.siteVisit.username === this.userDetails.username &&
      this.siteVisit.status !== 'CANCELING'
    );
  }

  cancelCheckout(): void {
    if (this.isOwner) {
      this.confirmatoryActionService
        .confirm(
          'Confirmation',
          'Warning: All local changes will be lost forever. To save changes, please check in instead ' +
          'of sending to the trash. Are you sure?',
          'Yes, Destroy My Changes',
          'No, I\'ve Changed My Mind',
          'lg'
        )
        .then((confirmed) => {
          if (confirmed) {
            this.isCancelling = true;
            this.error = null;
            this.waterSystemSiteVisitsService
              .deleteInProgress(this.siteVisit.id)
              .subscribe();
            this.waterSystemInspectionService
              .cancel(this.siteVisit.id)
              .subscribe((result) => {
                this.checkoutService.removeWaterSystemLocally(
                  this.siteVisit.id, this.userDetails.username
                );
                this.isCancelling = false;
                this.router.navigateByUrl('/home');
              });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  get isNotCheckedOut(): boolean {
    return this.siteVisit.username == null;
  }

  get isCheckedOutByOther(): boolean {
    return (
      this.siteVisit &&
      this.userDetails &&
      this.siteVisit.username &&
      this.userDetails.username &&
      this.siteVisit.username !== this.userDetails.username
    );
  }

  checkout(): void {
    this.isCheckoutWidgetLoading = true;
    this.setIsLoading(true);
    this.checkout$ = this.checkoutService.checkout(this.siteVisit.id).subscribe(
      () => {
        this.reloadSiteVisit();
        setTimeout(() => this.loadDetails(this.siteVisit.id), 60000);
      },
      (error) => {
        console.log(error);
        this.addError(error);
        this.isCheckoutWidgetLoading = false;
      }
    );
  }

  /**
   * @deprecated Check in of a PWS is not currently used, but code is being retained for future use.
   **/
  checkin(): void {
    if (this.error) {
      return;
    }
    this.isCheckingIn = true;
    this.isCheckoutWidgetLoading = true;
    this.waterSystemInspectionService
      .checkin(this.waterSystem.data.id)
      .subscribe(
        () => {
          this.checkoutService.removeWaterSystemLocally(
            this.waterSystem.data.id, this.userDetails.username
          );
          this.isCheckingIn = false;
          this.isCheckoutWidgetLoading = false;
          this.router.navigateByUrl('/home');
        },
        (error) => {
          console.log(
            'Error checking in water system {}',
            this.waterSystem.data.id
          );
          console.log(error);
          this.addError(error);
          this.isCheckingIn = false;
          this.isCheckoutWidgetLoading = false;
        }
      );
  }

  requestUnlock(): void {
    const requestor: EventRequestor = {
      email: this.userDetails.email,
    };
    const linkUrl: string =
      environment.applicationUrl +
      '/' +
      this.WATER_SYSTEM_URI +
      '/' +
      this.siteVisit.id;
    const unlockRequest: UnlockRequestEvent = {
      from: requestor,
      link: linkUrl,
      id: this.siteVisit.id,
    };
    const event: Event<UnlockRequestEvent> = {
      type: EventType.UNLOCK,
      data: unlockRequest,
    };
    const now = new Date();
    const sentToast = {
      text: 'The Unlock Request was sent at ' + now.toLocaleString(),
      classname: 'toast bg-success fade show',
      autohide: true,
      delay: 10000,
      showLoading: false,
    };
    this.toastService.show(sentToast);
    setTimeout(() => this.toastService.remove(sentToast), 10000);
    this.eventService.send(event).subscribe(
      () => {},
      (error) => {
        this.messageService.add({
          severity: Severity.ERROR,
          value: 'The Unlock Request failed due to: ' + error.message,
        });
      }
    );
  }

  reloadSiteVisit(): void {
    this.isCheckoutWidgetLoading = true;
    const checkedOutTimer = timer(60000, 2000);
    const refreshSiteVisit = checkedOutTimer.subscribe((t) => {
      if (!this.anyIsLoading) {
        const criteria = new SiteVisitCriteria();
        criteria.username = this.userDetails.username;
        const pageRequest = new PageRequest(0, 50);
        this.siteVisitService
          .searchRemotely(
            `${SiteVisitService.checkedOutKey}-${this.userDetails.username}`,
            criteria,
            pageRequest
          )
          .subscribe(
            (page) => {
              if (page === null || page.results.length === 0) {
                this.invalidPwsId = true;
              } else {
                for (const visit of page.results) {
                  if (this.siteVisit.id === visit.id) {
                    this.siteVisit = visit;
                    break;
                  }
                }
              }
              this.isCheckoutWidgetLoading = false;
              refreshSiteVisit.unsubscribe();
            },
            (error) => {
              console.log(error);
              this.addError(error);
              this.isCheckoutWidgetLoading = false;
            }
          );
      }
    });
  }

  getLocalCreatedDate(siteVisit: SiteVisit) {
    if (!siteVisit || !siteVisit.created) {
      return '';
    } else {
      return siteVisit.created;
    }
  }
}
