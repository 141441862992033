import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Unsubscribable } from 'rxjs';
import { WaterSystemService } from './water-system/water-system.service';
import { WaterSystemContactsService } from './water-system/water-system-contacts.service';
import { WaterSystemNotesService } from './water-system/water-system-notes.service';
import { WaterSystemOperatorsService } from './water-system/water-system-operators.service';
import { WaterSystemSiteVisitsService } from './water-system/water-system-site-visits.service';
import { WaterSystemFacilitiesService } from './water-system/water-system-facilities.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { WaterSystemDeficienciesService } from "./water-system/water-system-deficiencies.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Drinking Water Protection Site Visit Application';

  private waterSystemSyncSubscription: Unsubscribable;
  private waterSystemContactsSyncSubscription: Unsubscribable;
  private waterSystemOperatorsSyncSubscription: Unsubscribable;
  private waterSystemNotesSyncSubscription: Unsubscribable;
  private waterSystemSiteVisitSyncSubscription: Unsubscribable;
  private waterSystemFacilitiesSyncSubscription: Unsubscribable;
  private waterSystemDeficienciesSyncSubscription: Unsubscribable;

  constructor(private waterSystemService: WaterSystemService,
              private waterSystemContactsService: WaterSystemContactsService,
              private waterSystemNotesService: WaterSystemNotesService,
              private waterSystemOperatorsService: WaterSystemOperatorsService,
              private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
              private waterSystemFacilitiesService: WaterSystemFacilitiesService,
              private waterSystemDeficienciesService: WaterSystemDeficienciesService,
              private titleService: Title,
              private router: Router,
              private route: ActivatedRoute
              ) {}

  ngOnInit(): void {
    this.waterSystemSyncSubscription = interval(5000).subscribe(() => this.waterSystemService.sync());
    this.waterSystemContactsSyncSubscription = interval(5000).subscribe(() => this.waterSystemContactsService.sync());
    this.waterSystemOperatorsSyncSubscription = interval(5000).subscribe(() => this.waterSystemOperatorsService.sync());
    this.waterSystemNotesSyncSubscription = interval(10000).subscribe(() => this.waterSystemNotesService.sync());
    this.waterSystemSiteVisitSyncSubscription = interval(5000).subscribe(() => this.waterSystemSiteVisitsService.sync());
    this.waterSystemFacilitiesSyncSubscription = interval(10000).subscribe(() => this.waterSystemFacilitiesService.sync());
    this.waterSystemDeficienciesSyncSubscription = interval(5000).subscribe(() => this.waterSystemDeficienciesService.sync());
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).pipe(
      map(() => {
        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data)
    ).subscribe((event) => {
      const title = event['title'];
        if (title) {
          this.titleService.setTitle(title);
        }
    });
  }

  ngOnDestroy(): void {
    this.waterSystemSyncSubscription.unsubscribe();
    this.waterSystemContactsSyncSubscription.unsubscribe();
    this.waterSystemOperatorsSyncSubscription.unsubscribe();
    this.waterSystemNotesSyncSubscription.unsubscribe();
    this.waterSystemSiteVisitSyncSubscription.unsubscribe();
    this.waterSystemFacilitiesSyncSubscription.unsubscribe();
    this.waterSystemDeficienciesSyncSubscription.unsubscribe();
  }

}
