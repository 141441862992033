import { Component, OnInit, QueryList, ViewChildren, OnDestroy, Input} from '@angular/core';
import { NgbdSortableHeader, SortEvent } from '../../shared/table-sortable';
import { SiteVisitCriteria } from '../../domain/SiteVisitCriteria';
import { PageRequest } from '../../domain/page-request';
import { Unsubscribable, Observable } from 'rxjs';
import { District } from '../../domain/district';
import { Page } from '../../domain/page';
import { DistrictService } from '../../district.service';
import { SiteVisitService } from '../../site-visit.service';
import { KeycloakProfile } from 'keycloak-js';
import { ProfileService } from 'src/app/profile.service';
import { Profile } from 'src/app/domain/profile';
import { Router } from '@angular/router';
import { SiteVisit } from 'src/app/domain/site-visit';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { IamService } from 'src/app/utils/iam.service';
import { debounceTime, distinctUntilChanged, map, filter, take } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { COUNTY_TYPES } from 'src/app/shared/county-type';
import { CityService } from '../../shared/city/city.service';

@Component({
  selector: 'app-upcoming-site-visits',
  templateUrl: './upcoming-site-visits.component.html',
  styleUrls: ['./upcoming-site-visits.component.scss']
})
export class UpcomingSiteVisitsComponent implements OnInit, OnDestroy {

  userDetails: KeycloakProfile;
  profile: Profile;
  criteria = new SiteVisitCriteria();
  private pageRequest = new PageRequest(0, 10);

  districts$: Unsubscribable;
  districts: Array<District>;
  page: Page<SiteVisit>;

  errored = false;
  districtAssigned = false;
  isDistrictsLoading = true;
  isSiteVisitsLoading = true;

  isOnline: boolean;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @Input() advancedSearch = false;

  COUNTY_TYPES: any[];
  cities: string[];
  city: string;
  county: string;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private districtService: DistrictService,
    private siteVisitService: SiteVisitService,
    private connectivityService: ConnectivityService,
    private iamService: IamService,
    private cityService: CityService
  ) { }

  async ngOnInit() {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      if (!this.isOnline && online) {
        this.search();
      }
      this.isOnline = online;
    });
    if (this.advancedSearch) {
      this.initAdvancedSearch();
    }
    this.setupSearch();
  }

  async setupSearch() {
      this.userDetails = await this.iamService.getUserDetails();
      await this.getProfile();
      if (!this.districtAssigned) {
        this.router.navigate(['/profile']);
      } else {
        this.districts$ = this.districtService.findAll().subscribe(
          districts => {
            this.districts = districts;
            this.isDistrictsLoading = false;
          }
        );
        this.criteria.districtPartyId = this.profile.district;
        if (this.isOnline) {
          this.search();
        }
      }
  }

  requestPage(pageRequest: PageRequest) {
    this.pageRequest = pageRequest;
    this.search();
  }

  reset() {
    this.criteria = new SiteVisitCriteria();
    this.criteria.districtPartyId = this.profile.district;
    for (const header of this.headers.toArray()) {
      header.direction = '';
    }
    this.search();
  }

  search() {
    this.isSiteVisitsLoading = true;
    this.siteVisitService.search(this.criteria, this.pageRequest).subscribe(
      page => {
        this.page = page;
        this.isSiteVisitsLoading = false;
      },
      () => this.errored = true
    );
  }

  async getProfile() {
    await this.profileService.getProfile().then(
      resp => {
        this.profile = resp;
        if (this.hasRequiredProfileAttributes()) {
          this.districtAssigned = true;
        }
      },
      error => {
        this.districtAssigned = false;
        this.errored = true;
      }
    );
  }

  hasRequiredProfileAttributes(): boolean {
    if (this.profile.username != null && this.profile.username !== ''
        && this.profile.district != null
        && this.profile.profile != null
        && this.profile.profile.name != null && this.profile.profile.name !== ''
        && this.profile.profile.phoneNumber != null
        && this.profile.profile.contactAddress != null && this.profile.profile.contactAddress !== ''
        && this.profile.profile.initials != null && this.profile.profile.initials !== ''
        && this.profile.profile.signatureUrl != null && this.profile.profile.signatureUrl !== '') {
      return true;
    } else {
      return false;
    }
  }

  onSort({column, direction}: SortEvent) {
    const removedColumn = NgbdSortableHeader.doSort({column, direction}, this.criteria);
    if (removedColumn) {
      for (const header of this.headers.toArray()) {
        if (header.sortable === removedColumn) {
          header.direction = '';
          break;
        }
      }
    }
    this.search();
  }

  navigateToWaterSystemDetails(siteVisit: SiteVisit) {
    this.router.navigate(['/water-system', siteVisit.id], { state: { data: siteVisit }});
  }

  initAdvancedSearch() {
    this.cityService.getCitiesList()
      .pipe(take(1))
      .subscribe(cities => {
        this.cities = cities;
      });
    this.COUNTY_TYPES = Array.from(COUNTY_TYPES.values());
  }

  searchCity = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 2),
    map(term => this.cities.filter(city => new RegExp(term, 'mi').test(city)))
  )

  onSearchCitySelect(item: NgbTypeaheadSelectItemEvent) {
    if (!this.criteria.city) {
      this.criteria.city = [];
    }
    const index = this.criteria.city.indexOf(item.item);
    if (index === -1) {
      this.criteria.city.push(item.item.replace(/'/g, '\'\''));
    }
    this.city = null;
    item.preventDefault();
  }

  removeCityFromSearch(city: string) {
    const index = this.criteria.city.indexOf(city);
    if (index > -1) {
      this.criteria.city.splice(index, 1);
    }
  }

  searchCounty = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 2),
    map(term => this.COUNTY_TYPES.filter(county => new RegExp(term, 'mi').test(county)))
  )

  onSearchCountySelect(item: NgbTypeaheadSelectItemEvent) {
    for (const [key, value] of COUNTY_TYPES.entries()) {
      if (value === item.item) {
        this.criteria.county = key;
      }
    }
    this.county = null;
    item.preventDefault();
  }

  removeCountyFromSearch() {
    this.criteria.county = null;
    this.county = null;
  }

  ngOnDestroy(): void {
    if (this.districts$ != null) {
      this.districts$.unsubscribe();
    }
  }
}
