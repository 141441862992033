import { Injectable } from '@angular/core';
import { Observable, timer, throwError, from } from 'rxjs';
import { Checkout } from '../domain/checkout';
import { Contact } from '../domain/contact';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { concatMap, filter, map, take, tap, catchError, takeUntil } from 'rxjs/operators';
import { SyncSchedulerService } from './sync-scheduler.service';
import { LocalStorageService } from '../local-storage.service';
import { environment } from '../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { tables } from '../utils/app-db';
import { SyncResult, SyncType } from '../domain/sync';
import { ContactsService } from './contacts/contacts.service';
import { ContactsUtilityService } from './contacts/inventory-contacts/contacts-utility.service';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemContactsService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly CONTACT_SUB_ENDPOINT = 'contact';

  constructor(private http: HttpClient,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private syncScheduler: SyncSchedulerService,
              private contactsService: ContactsService,
              private contactsUtilityService: ContactsUtilityService) { }

  /**
   * Returns water system contacts saved locally
   * If none found, then will be check-out remotely if online
   * @param id of the water system
   */
  find(id: number): Observable<Checkout<Contact[]>> {
    return new Observable<Checkout<Contact[]>>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  findLocally(id: number): Observable<Checkout<Contact[]>> {
    return this.localStorageService.dbRetrieve<Checkout<Contact[]>>(tables.waterSystemContact, id);
  }

  findRemotely(id: number): Observable<Checkout<Contact[]>> {
    return this.http.get<Checkout<Contact[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.CONTACT_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Checkout<Contact[]>> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        tap(found => {
          this.store(id, found);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Saves the water system contacts locally
   * If online, then also saved to remote service, otherwise save is scheduled
   * @param id of the water system
   * @param waterSystemContacts the water system contacts
   */
  save(id: number, waterSystemContacts: Checkout<Contact[]>): void {
    this.cleanPhoneNumbers(waterSystemContacts);
    this.store(id, waterSystemContacts);
    this.contactsService.changeWaterSystemContacts(waterSystemContacts);
    this.syncScheduler.schedule(SyncType.Contacts, id).subscribe();
  }

  private saveRemotely(id: number, waterSystemContacts: Contact[]): Observable<Contact[]> {
    return this.http.put<Contact[]>(`${this.WATER_SYSTEM_URL}/${id}/${this.CONTACT_SUB_ENDPOINT}`, waterSystemContacts);
  }

  createRemotely(id: number, waterSystemContacts: Checkout<Contact[]>): Observable<{}> {
    this.cleanPhoneNumbers(waterSystemContacts);
    return this.saveRemotely(id, waterSystemContacts.data);
  }

  saveLocally(id: number, waterSystemContacts: Checkout<Contact[]>): void {
    this.store(id, waterSystemContacts);
    this.contactsService.changeWaterSystemContacts(waterSystemContacts);
  }

  private store(id: number, waterSystemContacts: Checkout<Contact[]>): void {
    this.contactsUtilityService.sortContacts(waterSystemContacts);
    this.localStorageService.dbStoreStorage(tables.waterSystemContact, id, waterSystemContacts);
  }

  status(id: number): Observable<SyncResult> {
    return this.syncScheduler.status(SyncType.Contacts, id);
  }

  /**
   * Syncs scheduled water systems contacts if online
   */
  sync(): void {
    if (this.connectivityService.isOnline()) {
      this.syncScheduler.scheduled(SyncType.Contacts).subscribe(syncRequests => {
        syncRequests.forEach(syncRequest => {
          this.findLocally(syncRequest.data.id).subscribe(waterSystemContacts => {
            if (waterSystemContacts) {
              this.saveRemotely(syncRequest.data.id, waterSystemContacts.data).subscribe(
                results => {
                  this.findLocally(syncRequest.data.id).subscribe(l => {
                    l.data = results;
                    this.saveLocally(syncRequest.data.id, l);
                    this.contactsService.changeWaterSystemContacts(l);
                  });
                  this.syncScheduler.unschedule(SyncType.Contacts, syncRequest.data.id,
                    SyncResultFactory.synced(SyncType.Contacts, syncRequest.data.id));
                },
                error => this.syncScheduler.unschedule(SyncType.Contacts, syncRequest.data.id,
                  this.translateSyncErrorResponse(SyncType.Contacts, syncRequest.data.id, error))
              );
            } else {
              this.syncScheduler.unschedule(SyncType.Contacts, syncRequest.data.id,
                SyncResultFactory.synced(SyncType.Contacts, syncRequest.data.id));
            }
          });
        });
      });
    }
  }

  // https://angular.io/guide/http#error-handling
  private translateSyncErrorResponse(type: SyncType, id: number, error: HttpErrorResponse | ErrorEvent): SyncResult {
    const messages: Array<string> = [];

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 403: {
          messages.push('You are unauthorized to save this water system contacts.');
          break;
        }
        case 422: {
          messages.push(error.message);
          break;
        }
        case 500: {
          messages.push('The API has encountered an error.');
          break;
        }
        case 503: {
          messages.push('The API is currently down.');
          break;
        }
        case 0: {
          messages.push('A communication error occurred.');
          break;
        }
        default: {
          messages.push('An unexpected error occurred.');
          break;
        }
      }
    } else {
      messages.push(error.error.message);
    }

    return SyncResultFactory.error(type, id, messages);
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemContact, id);
  }

  private cleanPhoneNumbers(contacts: Checkout<Contact[]>) {
    if (contacts.data && contacts.data.length > 0) {
      contacts.data.forEach(contact => {
        if (contact.phone && contact.phone !== '') {
          contact.phone = contact.phone.replace(/\D+/g, '');
        }
      });
    }
  }
}
