import { Component, Input, OnInit } from '@angular/core';
import { Checkout } from 'src/app/domain/checkout';
import { Contact, ContactAction } from 'src/app/domain/contact';
import { Severity } from 'src/app/message/Severity';
import { MessageService } from 'src/app/message/message.service';
import { WaterSystemContactsService } from '../../water-system-contacts.service';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';

@Component({
  selector: "app-inventory-contacts",
  templateUrl: "./inventory-contacts.component.html",
  styleUrls: ["./inventory-contacts.component.scss"],
})
export class InventoryContactsComponent implements OnInit {
  @Input() contacts: Checkout<Contact[]>;

  public isContactsMailingCollapsed = false;

  constructor(
    private messageService: MessageService,
    private waterSystemContactsService: WaterSystemContactsService,
    private confirmatoryActionService: ConfirmatoryActionService
  ) {}

  ngOnInit() {}

  delete(deleteIndex: number) {
    this.messageService.clear();
    this.confirmatoryActionService
      .confirm(
        "Confirmation",
        "Are you sure you want to delete this contact?",
        "Yes",
        "No",
        "sm"
      )
      .then((confirmed) => {
        if (confirmed) {
          if (deleteIndex > -1) {
            const contact = this.contacts.data[deleteIndex];
            if (contact.action === ContactAction.Create) {
              contact.action = ContactAction.Remove;
              this.waterSystemContactsService.save(
                contact.pwsId,
                this.contacts
              );
              this.messageService.add({
                severity: Severity.SUCCESS,
                value: "Contact has been deleted.",
              });
            } else {
              if (contact.action === ContactAction.Update) {
                contact.action = ContactAction.Erase;
              } else {
                contact.action = ContactAction.Delete;
              }
              this.waterSystemContactsService.save(
                contact.pwsId,
                this.contacts
              );
              this.messageService.add({
                severity: Severity.SUCCESS,
                value: "Contact has been flagged for deletion.",
              });
            }
          }
          window.scrollTo(0, 0);
        }
      })
      .catch(() => {});
  }

  restore(restoreIndex: number) {
    this.messageService.clear();
    if (this.contacts.data[restoreIndex].action === ContactAction.Erase) {
      this.contacts.data[restoreIndex].action = ContactAction.Update;
    } else if (
      this.contacts.data[restoreIndex].action === ContactAction.Delete
    ) {
      this.contacts.data[restoreIndex].action = ContactAction.Update;
    }
    this.waterSystemContactsService.save(
      this.contacts.data[restoreIndex].pwsId,
      this.contacts
    );
    this.messageService.add({
      severity: Severity.SUCCESS,
      value: "Contact has been Restored.",
    });
    window.scrollTo(0, 0);
  }

  isNotDeleted(contact: Contact): boolean {
    return (
      contact.action !== ContactAction.Delete &&
      contact.action !== ContactAction.Erase
    );
  }

  canRestore(contact: Contact): boolean {
    if (!Array.isArray(this.contacts.data) || !this.contacts.data.length) {
      return true;
    }
    const otherContacts = this.contacts.data.filter((c) => c.id != contact.id);
    const samePrimaryRole = otherContacts.filter(
      (c) =>
        c.isPrimary &&
        c.isPrimary === contact.isPrimary &&
        c.role === contact.role &&
        this.isNotDeleted(c) &&
        this.isNotRemoved(c)
    );
    return samePrimaryRole.length === 0;
  }

  isNotRemoved(contact: Contact): boolean {
    return contact.action == null
        || (contact.action != null && contact.action !== ContactAction.Remove);
  }
}
