import { Component, OnInit, OnDestroy } from '@angular/core';
import { Unsubscribable } from 'rxjs';
import { District } from '../domain/district';
import { Profile } from '../domain/profile';
import { ProfileService } from '../profile.service';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { DistrictService } from '../district.service';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { MessageService } from '../message/message.service';
import { Severity } from '../message/Severity';
import {IamService} from '../utils/iam.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  userDetails: KeycloakProfile;
  profileComplete = false;
  profile: Profile;
  errored = false;
  isSaving = false;
  isDistrictsLoading = true;
  isProfileLoading = true;
  isSignatureLoading = true;
  isOnline: boolean;
  showImagesOnlyMessage: boolean;
  signatureFile: File;

  private profile$: Unsubscribable;
  private districts$: Unsubscribable;
  districts: Array<District>;

  constructor(
    private keycloakService: KeycloakService,
    private profileService: ProfileService,
    private districtService: DistrictService,
    private connectivityService: ConnectivityService,
    private messageService: MessageService
  ) { }

  async ngOnInit() {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      this.isOnline = online;
    });
    this.districts$ = this.districtService.findAll().subscribe(
      resp => {
        this.districts = resp;
        this.isDistrictsLoading = false;
      }
    );
    if (await this.keycloakService.isLoggedIn()) {
      this.userDetails = await this.keycloakService.loadUserProfile();
      await this.getProfile();
    }
  }

  async getProfile() {
    await this.profileService.getProfile().then(
      resp => {
        this.profile = resp;
        if (this.profile.profile == null) {
          this.profile.profile = {
            name: null,
            phoneNumber: null,
            contactAddress: null,
            initials: null,
            signatureUrl: null
          };
        }
        this.formatPhoneNumber();
        this.getSignature();
        if (this.isProfileComplete()) {
          this.profileComplete = true;
        }
        this.isProfileLoading = false;
      },
      () => {
        this.profile = {
          username: this.userDetails.username,
          district: null,
          profile: {
            name: null,
            phoneNumber: null,
            contactAddress: null,
            initials: null,
            signatureUrl: null
          }
        };
        this.isSignatureLoading = false;
        this.isProfileLoading = false;
      }
    );
  }

  save(): void {
    this.messageService.clear();
    this.profileComplete = false;
    this.isSaving = true;
    if (!this.isProfileComplete()) {
      this.messageService.add({ severity: Severity.ERROR, value: 'The profile is missing required value(s).' });
      this.isSaving = false;
      window.scrollTo(0, 0);
      return;
    }
    this.profile$ = this.profileService.saveProfile(this.profile, this.signatureFile).subscribe(
      () => {
          this.formatPhoneNumber();
          this.getSignature();
          this.messageService.add({ severity: Severity.SUCCESS, value: 'The profile has been saved.' });
          this.profileComplete = true;
          this.isSaving = false;
        },
      () => { this.errored = true; },
      () => this.isSaving = false
    );
  }

  isProfileComplete(): boolean {
    if (this.profile.username != null && this.profile.username !== ''
        && this.profile.district != null
        && this.profile.profile != null
        && this.profile.profile.name != null && this.profile.profile.name !== ''
        && this.profile.profile.phoneNumber != null
        && this.profile.profile.contactAddress != null && this.profile.profile.contactAddress !== ''
        && this.profile.profile.initials != null && this.profile.profile.initials !== ''
        && this.profile.profile.signatureUrl != null && this.profile.profile.signatureUrl !== '') {
      return true;
    } else {
      return false;
    }
  }

  formatPhoneNumber() {
    if (this.profile.profile != null && this.profile.profile.phoneNumber && this.profile.profile.phoneNumber !== '') {
      this.profile.profile.phoneNumber = this.profile.profile.phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }
  }

  getSignature() {
    if (this.profile.profile == null) {
      this.isSignatureLoading = false;
      return;
    }
    if (!this.profile.profile.signatureUrl || this.profile.profile.signatureUrl === '') {
      this.isSignatureLoading = false;
      return;
    }
    this.profileService.downloadSignature(this.profile).subscribe(resp => {
      this.signatureFile = resp;
      this.drawSignatureThumbnail();
      this.isSignatureLoading = false;
    });
  }

  onSignatureChange(event) {
    this.showImagesOnlyMessage = false;
    if (event.target.files && event.target.files.length > 0) {
      const [file] = event.target.files;
      if (file.type.startsWith('image')) {
        this.signatureFile = file;
        this.drawSignatureThumbnail();
        this.profile.profile.signatureUrl = file.name;
      } else {
        this.showImagesOnlyMessage = true;
        event.preventDefault();
        return;
      }
    }
  }

  drawSignatureThumbnail() {
    const reader = new FileReader();
    const signatureThumbnailId = 'signatureThumbnail';
    reader.onload = function(event) {
      const img = new Image();
      img.onload = function() {
        const signatureThumbnailCanvas = <HTMLCanvasElement> document.getElementById(signatureThumbnailId);
        const ctx = signatureThumbnailCanvas.getContext('2d');
        if (img.width < 200) {
          signatureThumbnailCanvas.width = img.width;
          signatureThumbnailCanvas.height = img.height;
        } else {
          const ratio = img.width / 200;
          signatureThumbnailCanvas.width = 200;
          signatureThumbnailCanvas.height = img.height / ratio;
        }
        ctx.drawImage(img, 0, 0, signatureThumbnailCanvas.width, signatureThumbnailCanvas.height);
      };
      img.src = event.target.result.toString();
    };
    reader.readAsDataURL(this.signatureFile);
  }

  openSignatureImage() {
    if (this.signatureFile != null) {
      window.open( window.URL.createObjectURL(this.signatureFile), '_blank');
    }
  }

  ngOnDestroy(): void {
    if (this.districts$ != null) {
      this.districts$.unsubscribe();
    }
    if (this.profile$ != null) {
      this.profile$.unsubscribe();
    }
  }
}
