import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { KeycloakService } from 'keycloak-angular';
import { LocalStorageService } from '../local-storage.service';
import { of } from 'rxjs';
import { KeycloakTokenStorage } from '../domain/keycloak';

@Injectable({
  providedIn: 'root'
})
export class IamService {

  private readonly OFFLINE_TOKEN = 'keycloak-token';
  private readonly OFFLINE_USER_DETAILS_KEY = 'keycloak-user-details';
  private readonly OFFLINE_USER_ROLES_KEY = 'keycloak-user-roles';

  constructor(private connectivityService: ConnectivityService,
              private keycloakService: KeycloakService,
              private localStorageService: LocalStorageService
  ) { }

  isOfflineAuthenticated(): boolean {
      const token = this.getToken();
      return token && token.idTokenParsed.aud === this.keycloakService.getKeycloakInstance().clientId;
  }

  storeToken(token: KeycloakTokenStorage) {
    this.localStorageService.store(this.OFFLINE_TOKEN, token);
  }

  getToken(): KeycloakTokenStorage {
    return this.localStorageService.retrieve(this.OFFLINE_TOKEN) as KeycloakTokenStorage;
  }

  storeUserDetails(userDetails: KeycloakProfile) {
    this.localStorageService.store(this.OFFLINE_USER_DETAILS_KEY, userDetails);
  }

  getUserDetails(): Promise<KeycloakProfile> {
    if (this.connectivityService.isOnline()) {
      return this.keycloakService.loadUserProfile();
    } else {
      const userDetails = this.localStorageService.retrieve(this.OFFLINE_USER_DETAILS_KEY) as KeycloakProfile;
      return of(userDetails).toPromise();
    }
  }

  storeUserRoles(userRoles: string[]) {
    this.localStorageService.store(this.OFFLINE_USER_ROLES_KEY, userRoles);
  }

  getUserRoles(): string[] {
    if (this.connectivityService.isOnline()) {
      return this.keycloakService.getUserRoles(true);
    } else {
      return this.localStorageService.retrieve(this.OFFLINE_USER_ROLES_KEY) as string[];
    }
  }

  isUserInRole(role: string): boolean {
    return this.getUserRoles().includes(role);
  }
}
